<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Minimum Stay
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="minimumm-form">
          <v-text-field
            label="From"
            type="date"
            v-model="fields.from"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="To"
            type="date"
            v-model="fields.to"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="Number of Nights"
            outlined
            v-model="fields.number_of_night"
            background-color="white"
            type="number"
            v-show="false"
            min="1"
          ></v-text-field>
          <v-text-field
            label="Price *"
            type="number"
            min="0.00"
            step="1.00"
            prefix="£"
            outlined
            v-model="fields.price"
            background-color="white"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="minimumm-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      minimumstay: {},
      errors: {},
      fields: {
        from: null,
        to: null,
        price: null,
        number_of_night: 1,
      },
    };
  },

  methods: {
    open(minimumstay = null) {
      if (minimumstay !== null) {
        this.minimumstay = minimumstay;
        this.isEditing = true;
        this.fields.from = minimumstay.from;
        this.fields.to = minimumstay.to;
        this.fields.number_of_night = minimumstay.number_of_night;
        this.fields.price = minimumstay.price;
      }
      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.minimumStayId = this.minimumstay.id;
      }

      this.$store
        .dispatch("arnprior/settingsStore/saveMinimumStay", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.minimumstay = {};
      this.fields.from = null;
      this.fields.to = null;
      this.fields.price = null;
      this.fields.number_of_night = 1;
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>

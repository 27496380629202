<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Settings</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card>
            <v-toolbar flat dense dark color="accent">
              <v-toolbar-title>Glamping Pod Settings</v-toolbar-title>
            </v-toolbar>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Check In Time</th>
                  <td>
                    <div class="pt-2 pb-2">
                      <v-text-field
                        value="15:00"
                        type="time"
                        outlined
                        hide-details
                      ></v-text-field>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Check Out Time</th>
                  <td>
                    <div class="pt-2 pb-2">
                      <v-text-field
                        value="10:00"
                        type="time"
                        outlined
                        hide-details
                      ></v-text-field>
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-card>
            <v-toolbar flat dark dense color="accent">
              <v-toolbar-title>One Night Stay Dates</v-toolbar-title>
              <div class="spacer"></div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                    @click="$refs.minimumStayDialog.open()"
                  >
                    <v-icon x-small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add New</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>

            <v-data-table
              :headers="tableHeaders"
              :items="minimumStay"
              class="mt-4"
              :multi-sort="true"
              no-data-text="No Minimum Stay Dates found"
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.minimumStayDialog.open(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                      @click="openDelete(item)"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Archive</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <minimum-stay-dialog ref="minimumStayDialog" />
    <v-dialog v-model="deleteMinimumStay.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Customer</v-card-title>
        <v-card-text>Are you sure you wish to archive ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteMinimumStay.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import minimumStayDialog from "./components/MinimumStayDialog.vue";

export default {
  components: {
    minimumStayDialog,
  },
  data() {
    return {
      tableHeaders: [
        { text: "From", value: "formatted_dates.from" },
        { text: "To", value: "formatted_dates.to" },

        { text: "Price", value: "price" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      breadcrumbs: [
        {
          text: "Glamping Pods",
          disabled: true,
        },
        {
          text: "Settings",
          disabled: true,
        },
      ],
      deleteMinimumStay: {
        dialog: false,
        stay: {},
        loading: false,
      },
    };
  },
  computed: {
    minimumStay() {
      return this.$store.getters["arnprior/settingsStore/minimumStays"];
    },
  },

  methods: {
    openDelete(stay) {
      this.deleteMinimumStay.stay = stay;
      this.deleteMinimumStay.dialog = true;
    },

    resetDelete() {
      this.deleteMinimumStay.dialog = false;
      this.deleteMinimumStay.stay = {};
      this.deleteMinimumStay.loading = false;
    },

    saveDelete() {
      this.deleteMinimumStay.loading = true;

      this.$store
        .dispatch("arnprior/settingsStore/deleteMinimumStay", {
          appId: this.$route.params.id,
          minimumStayId: this.deleteMinimumStay.stay.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteMinimumStay.loading = false;
        });
    },
  },
};
</script>
